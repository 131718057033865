function randomIntFromInterval(min: number, max: number): number {  // min and max included
  return Math.floor(Math.random() * (max - min + 1) + min);
}

function getRandomBoard(rows: number, cols: number, numberOfOnes: number): number[][] {
  const board: number[][] = [];

  for (let i = 0; i < rows; i++) {
    const row: number[] = [];

    for (let j = 0; j < cols; j++) {
      row.push(0);
    }

    board.push(row);
  }

  for (let i = 0; i < numberOfOnes; i++) {
    const col = randomIntFromInterval(0, cols - 1);
    const row = randomIntFromInterval(0, rows - 1);

    board[row][col] = 1;
  }

  return board;
}


export class GameOfLife {
  readonly board: number[][] = [];
  reachedBalance = false;

  constructor(private readonly rows: number, private readonly cols: number, private readonly populationSize: number) {
    this.board = getRandomBoard(rows, cols, populationSize);
  }

  updateRows(rows: number): GameOfLife {
    if (this.rows === rows) {
      return this;
    } else {
      return new GameOfLife(rows, this.cols, this.populationSize);
    }
  }

  restart(): GameOfLife {
    return new GameOfLife(this.rows, this.cols, this.populationSize);
  }

  update(cols: number, rows: number): GameOfLife {
    if (this.rows === rows && this.cols === cols) {
      return this;
    } else {
      return new GameOfLife(rows, cols, this.populationSize);
    }
  }

  step(): GameOfLife {
    if (!this.reachedBalance) {
      let changed = false;
      for (let i = 0; i < this.rows; i++) {
        for (let j = 0; j < this.cols; j++) {
          const currState = this.board[i][j];
          const newState = this.nextState(currState, this.countNeighbors(i, j));
          this.board[i][j] = newState;

          if (!changed && currState !== newState) {
            changed = true;
          }
        }
      }

      this.reachedBalance = !changed;
    }

    return this;
  }

  private countNeighbors(row: number, col: number): number {
    const rowAbove = this.firstRow(row) ? this.rows - 1 : row - 1;
    const rowBelow = this.lastRow(row) ? 0 : row + 1;
    const colLeft = this.firstCol(col) ? this.cols - 1 : col - 1;
    const colRight = this.lastCol(col) ? 0 : col + 1;

    return this.board[rowAbove][colLeft] + this.board[rowAbove][col] + this.board[rowAbove][colRight]
    + this.board[row][colLeft] +  this.board[row][colRight]
    + this.board[rowBelow][colLeft] + this.board[rowBelow][col] + this.board[rowBelow][colRight];
  }

  private firstRow(row: number): boolean {
    return row === 0;
  }

  private lastRow(row: number): boolean {
    return row === this.rows - 1;
  }

  private firstCol(col: number): boolean {
    return col === 0;
  }

  private lastCol(col: number): boolean {
    return col === this.cols - 1;
  }

  private nextState(state: number, neighbors: number): number {
    if (state === 0) {
      return neighbors === 3 ? 1 : 0;
    } else if (neighbors === 2 || neighbors === 3) {
      return 1;
    }
    return 0;
  }
}
