import React from "react";
import {Layer, Rect, Stage} from "react-konva";

interface GameOfLifeState {
  readonly board: number[][];
  rows: number;
  cols: number;
  boardWith: number;
  boardHeight: number;
}

function keyForCell(row: number, col: number, maxRows: number, maxCols: number): string {
  return `${row}-${col}-${maxRows}-${maxCols}`
}

export function GameOfLifeBoard(props: GameOfLifeState) {
  const widthPerCell = props.boardWith / props.cols;
  const heightPerCell = props.boardHeight / props.rows;
  const cells = [];

  for (let i = 0; i < props.board.length; i++) {
    const row = props.board[i];
    for (let j = 0; j < row.length; j++) {
      if (props.board[i][j] === 1) {
        cells.push(<Rect width={widthPerCell} height={heightPerCell} x={j * widthPerCell} y={i * heightPerCell} fill="white" key={keyForCell(i, j, props.rows, props.cols)}/>);
      }
    }
  }

  return (
      <Stage height={props.boardHeight} width={props.boardWith}>
        <Layer>
          {
            cells
          }
        </Layer>
      </Stage>
  );
}

