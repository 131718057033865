import React, {ChangeEvent} from 'react';
import {GameOfLife} from "../game-of-life-board/GameOfLife";
import {GameOfLifeBoard} from "../game-of-life-board/GameOfLifeComponent";
import './Controls.css'
import {About} from "../about/About";
import {Footer} from "../footer/Footer";

const DEFAULT_ROWS = 10;
const DEFAULT_COLS = 16;
const DEFAULT_INITIAL_POP = 50;

export interface GameOfLifeConfig {
  fps: number;
  cols: number;
  rows: number;
  gameOfLife: GameOfLife;
  populationSize: number;
  boardWidth: number;
  boardHeight: number;
  visibleSection: 'settings' | 'about' | 'none';
}

class Controls extends React.Component<any, GameOfLifeConfig> {
  timerID: any;
  container: HTMLElement | null | undefined;

  constructor(props: any) {
    super(props);
    this.state = {
      fps: 10,
      rows: DEFAULT_ROWS,
      cols: DEFAULT_COLS,
      gameOfLife: new GameOfLife(DEFAULT_ROWS, DEFAULT_COLS, DEFAULT_INITIAL_POP),
      populationSize: DEFAULT_INITIAL_POP,
      boardWidth: 1000,
      boardHeight: 1000,
      visibleSection: 'none'
    };
    this.handleFpsChange = this.handleFpsChange.bind(this);
    this.handleColsChange = this.handleColsChange.bind(this);
    this.handlePopulationSizeChange = this.handlePopulationSizeChange.bind(this);
  }

  componentDidMount() {
    this.checkSize();
    window.addEventListener("resize", this.checkSize);

    this.setupTimer(this.state.fps);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.checkSize);

    clearInterval(this.timerID);
  }

  checkSize = () => {
    if (this.container) {
      const width = this.container.offsetWidth;
      const height = this.container.offsetHeight;

      this.setState(state => {
        const rows = this.calcRows(width, height, state.cols);

        return {
          boardWidth: width,
          boardHeight: height,
          rows: rows,
          gameOfLife: state.gameOfLife.updateRows(rows)
        }
      });
    }
  };

  calcRows(boardWidth: number, boardHeight: number, cols: number): number {
    const widthPerTile = boardWidth / cols;
    return Math.ceil(boardHeight / widthPerTile);
  }

  handleFpsChange(event: ChangeEvent<HTMLInputElement>) {
    const fps = event.target.valueAsNumber;

    this.setState({
      fps: fps
    });
    this.setupTimer(fps);
  }

  handleColsChange(event: ChangeEvent<HTMLInputElement>) {
    this.setState(state => {
      const cols = this.minOne(event.target.valueAsNumber);
      const rows = this.calcRows(state.boardWidth, state.boardHeight, cols);
      return {
        cols: cols,
        rows: this.calcRows(state.boardWidth, state.boardHeight, cols),
        gameOfLife: state.gameOfLife.update(cols, rows)
      }
    });
  }

  handlePopulationSizeChange(event: ChangeEvent<HTMLInputElement>) {
    this.setState(state => {
      const populationSize = this.trim(event.target.valueAsNumber, 1, state.cols * state.rows);
      return {
        populationSize,
        gameOfLife: new GameOfLife(state.rows, state.cols, populationSize)
      }
    });
  }

  private minOne(number: number): number {
    return number > 0 ? number : 1;
  }

  private trim(number: number, min: number, max: number): number {
    return Math.max(Math.min(number, max), min)
  }

  private tick() {
    this.setState(state => {
      return {
        gameOfLife: state.gameOfLife.step()
      }
    })
  }

  private setupTimer(fps: number) {
    if (this.timerID) {
      clearInterval(this.timerID);
    }

    if (fps > 0) {
      this.timerID = setInterval(() => this.tick(), 1000 / fps);
    }
  }

  private handleSectionButtonClicked(section: 'settings' | 'about' | 'none') {
    if (section === this.state.visibleSection) {
      section = 'none';
    }
    this.setState({visibleSection: section});
  }

  private renderSectionButton(section: 'settings' | 'about') {
    return (
        <button className={this.state.visibleSection === section ? 'selected' : ''}
                onClick={() => this.handleSectionButtonClicked(section)} aria-label={section}>
          {section}
        </button>
    );
  }

  render() {
    return (
        <div className={"board-and-controls"}>
          <main className={"board-wrapper"} ref={node => this.container = node}>
            <GameOfLifeBoard board={this.state.gameOfLife.board} rows={this.state.rows} cols={this.state.cols} boardHeight={this.state.boardHeight} boardWith={this.state.boardWidth}/>
          </main>

          <header>
            <h1>Conway's Game of Life</h1>
            {this.renderSectionButton('settings')}
            {this.renderSectionButton('about')}
            <button onClick={() => this.setState(state => {return {gameOfLife: state.gameOfLife.restart()}})}>Restart</button>
          </header>
          <section className={this.state.visibleSection === "settings" ? "visible" : ""}>
            <label htmlFor="fps">FPS</label>
            <input id="fps" type="number" min={1} max={60} value={this.state.fps} onChange={this.handleFpsChange}/>

            <label htmlFor="cols">Cols</label>
            <input id="cols" type="number" min={1} value={this.state.cols} onChange={this.handleColsChange}/>

            <label htmlFor="populationSize">Population size</label>
            <input id="populationSize" type="number" min={1} value={this.state.populationSize} onChange={this.handlePopulationSizeChange}/>
          </section>
          <section className={this.state.visibleSection === "about" ? "visible" : ""}>
            <About/>
          </section>
          <Footer/>
        </div>
    );
  }
}

export default Controls;
