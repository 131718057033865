import React from 'react';
import Controls from "../controls/Controls";

function Root() {
  return (
      <div className="App">
        <Controls/>
      </div>
  );
}

export default Root;
